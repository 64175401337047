import { useMemoize } from '@vueuse/core';
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';

import { useAuthStore } from '$common/modules/auth/stores/auth.ts';
import { useFetchRbacRoleForUser } from '$tenant/modules/rbac/data/rbac.api';

const isRoleEmpty = (roles?: string[]) =>
  typeof roles === 'undefined' || roles == null || (roles ?? []).length === 0;

export const useRbacStore = defineStore('Rbac', () => {
  const activeRoles = ref<string[]>(window.roles ?? []);
  const authStore = useAuthStore();

  const hasAnyRoles = useMemoize(
    (roles: string[] = [], strict = false) =>
      isRoleEmpty(roles) && !strict
        ? true
        : (roles ?? []).some((role) => activeRoles.value.includes(role)),
    {
      getKey(roles: string[] = [], strict = false) {
        return JSON.stringify({ roles, strict, activeRoles: activeRoles.value });
      },
    },
  );

  const hasAllRoles = useMemoize(
    (roles: string[] = [], strict = false) =>
      isRoleEmpty(roles) && !strict
        ? true
        : (roles ?? []).every((role) => activeRoles.value.includes(role)),
    {
      getKey(roles: string[] = [], strict = false) {
        return JSON.stringify({ roles, strict, activeRoles: activeRoles.value });
      },
    },
  );

  const { execute: refreshRoles, data, isLoading } = useFetchRbacRoleForUser();
  watch(
    () => data.value,
    (response) => {
      activeRoles.value = response?.map(({ name }) => name) ?? activeRoles.value;
    },
  );

  function refresh() {
    if (authStore.currentUser?.id) {
      void refreshRoles(authStore.currentUser.id, { fields: ['name'] });
    }
  }

  function clear() {
    activeRoles.value = [];
  }

  return {
    roles: activeRoles,
    refreshing: isLoading,
    clear,
    hasAnyRoles,
    hasAllRoles,
    refresh,
  };
});
